<template>
	<div class="main-contents point">
		<div class="tit">수요기업 포인트관리<img src="/images/admin/points.png" alt="포인트"/></div>
		<div class="search-box">
			<InputComp title="수요기업명" v-model="input.searchStr" @keyup.enter="goPage(1)"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="goPage(1)"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="5%">
					<col width="25%">
					<col width="30%">
					<col width="30%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>수요기업명</th>
						<th>총 보유 포인트</th>
						<th>미확정 포인트 <span>{{uncfmCnt}}</span></th>
						<th>포인트 관리</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!list || list.length == 0"><td colspan="5" class="none">포인트가 없습니다!</td></tr>
					<tr v-for="(item, idx) in list" :key="idx" v-else>
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (idx + 1)}}</td>
						<td class="score">{{item.corpNm}}</td>
						<td class="score point">{{item.pointSum | number}} <img src="/images/admin/dco_point.png" alt="포인트"/></td>
						<td class="score point ud" :class="{ ud : item.pointUncfmSum > 0 }">{{item.pointUncfmSum | number}} <img src="/images/admin/point_none.png" alt="미확정포인트"/></td>
						<td class="score resume"><div class="btn" @click="goDetail(item)">포인트 관리 &#9654;</div></td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';
export default {
	components : { PagingComp },
	data() {
		return {
			input: {
				corpDivCd: '10',
				searchStr: '',
				//orderGubun: 'regiDsc',
				pageIndex : '1',
			}, // 검색조건
			uncfmCnt: 0,
			list : [],
			pageInfo : {},
		};
	},
	mounted() {
		if(this.$route.params){
			for(var k in this.$route.params){
				if(this.input[k] !== undefined){
					this.input[k] = this.$route.params[k];
				}
			}
		}
		this.getList();
	},
	methods: {
		getList() {
			this.$.httpPost('/api/mem/pit/getCorpPointList', this.input)
				.then(res => {
					this.list = res.data.list;
					this.uncfmCnt = res.data.uncfmCnt;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},
		changeOrder(orderGubun){
			//console.log('changeOrder', orderGubun, this.input.orderGubun)
			if(orderGubun != this.input.orderGubun){
				this.input.orderGubun = orderGubun;
				this.goPage(1);
			}
		},

		isNew(item){
			return item.pointCfmYn == 'N';
		},

		goDetail(item){
			//console.log('goDetail', item)
			var corpSeq = item.corpSeq;
			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			};
			this.$router.push({name:'MEM925M02', query:{ corpSeq }, params })
		},
	},
};
</script>
